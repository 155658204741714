import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "../styles/ContactFormStyle.css"

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const [formStatus, setFormStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.message) {
      setFormStatus("Merci de remplir tous les champs obligatoires.");
      return;
    }

    // Envoi de l'email via EmailJS
    emailjs
      .send(
        "service_2zu91sg",     // Remplacez par votre Service ID
        "template_jus4wym",    // Remplacez par votre Template ID
        formData,
        "iAVJ3-EaNK4n8obHe"      // Remplacez par votre clé publique
      )
      .then(
        (response) => {
          console.log("Email envoyé avec succès:", response);
          setFormStatus("Votre message a bien été envoyé ! Merci.");
          setFormData({
            name: "",
            email: "",
            subject: "",
            message: ""
          });
        },
        (error) => {
          console.error("Erreur lors de l'envoi de l'email:", error);
          setFormStatus("Erreur lors de l'envoi. Veuillez réessayer.");
        }
      );
  };

  return (
    <div className="contact-form" id="contact">
      <h2>Contactez-moi</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Nom :</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="votre nom"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        
        <div>
          <label htmlFor="email">Email :</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="votre e-mail"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        
        <div>
          <label htmlFor="subject">Sujet :</label>
          <input
            type="text"
            id="subject"
            name="subject"
            placeholder="pourquoi me contactez-vous ?"
            value={formData.subject}
            onChange={handleChange}
          />
        </div>
        
        <div>
          <label htmlFor="message">Message :</label>
          <textarea
            id="message"
            name="message"
            placeholder="votre message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" className="button-app">Envoyer</button>
      </form>
      
      {formStatus && <p className="form-status">{formStatus}</p>}
    </div>
  );
}

export default ContactForm;
