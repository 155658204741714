import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import '../styles/PopoverStyle.css';
import { Link } from 'react-router-dom';

const PopoverComponent = ({ 
    title, 
    content,
    linkText = "voir exemple",
    linkHref = "#",
    buttonText = "Ouvrir", 
    placement = "bottom",
    buttonVariant = "success",
    buttonClassName = "button-app" 
  }) => {
    const popover = (
      <Popover id="popover-basic" className="custom-popover">
        <Popover.Header>{title}</Popover.Header>
        <Popover.Body>
          {content}
          <div className='popover-link'>
          <button type='button' className='button-app'>
            <Link to={linkHref} >{linkText}</Link>
          </button>

            

          </div>
        </Popover.Body>
      </Popover>
    );
  
    return (
      <OverlayTrigger 
        trigger="click" 
        placement={placement} 
        overlay={popover}
        rootClose={true}
      >
        <Button variant={buttonVariant} className={buttonClassName}>
          {buttonText}
        </Button>
      </OverlayTrigger>
    );
  };
  
  export default PopoverComponent;