import Header from '../components/Header';
import DonutChart from '../components/SkillChart';
import "../styles/headerStyle.css"
import "../styles/SkillChartStyle.css"
import "../styles/donutStyle.css"
import PdfDownload from '../components/downloadPdf';
import DownloadCV from '../components/downloadPdf';
import NavbarSite from '../components/Navbar';
 function DonutPage () {

    return (
        <div className='container-app ' >
        <Header/>
        <NavbarSite />
        <div className='donut'>
        <h1>Compétences</h1>
         <DownloadCV/>
        < DonutChart />
         </div>
         <div className='cv'>
            <img alt='cv' src='./images/CV.png' />
         </div>
        </div>

        
    )
}

export default DonutPage