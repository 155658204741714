import {Button,Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/NavbarSiteStyle.css"
function CardSite({ img,title,text,}) {

    return (
        <div className="card-container">
        <Card className='card'>
            <Card.Img variant="top" src={img} alt="Card image" />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{text}</Card.Text>
                <Button type='button' className='button-app button-card' href="./#contact">
                plus de renseignement
                </Button>
            </Card.Body>
    </Card>
    </div>
    )
}

export default CardSite;