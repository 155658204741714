import React from "react";
import { Chart as ChartJS,ArcElement,Tooltip,Legend, } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import "../styles/donutStyle.css"
ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
    labels: ['React', 'Node.js', 'JavaScript', 'SQL', 'CSS', 'HTML'],
    datasets: [
      {
        data: [85, 75, 90, 70, 80, 95],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
      },
    ],
  };

  const options = {
    cutout: '50%', // Réglage du diamètre interne (trou au centre)
    responsive: true,
    maintainAspectRatio: false, // Permet d'ajuster la taille du canvas
  };
  
  const DonutChart = () => (
    <div className="donuts-container">
      
      <Doughnut data={data} options={options} />
    </div>
  );
  export default DonutChart

