import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import DonutPage from './pages/DonutPage';
import Home from './pages/HomePage';
import Portfolio from './components/Portfolio';
import Project from './components/Project';

const router = createBrowserRouter([
  {
    path:"/",
    element: <App/>
  },
  {
    path:"/Home",
    element:<Home />
  },
  {
    path:"/cv",
    element:<DonutPage/>
  },
  {
    path:"/portfolio",
    element:<Portfolio />
  },
  {
    path:"/project",
    element:<Project />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
