import React from 'react';
import { Button } from 'react-bootstrap';
import { jsPDF } from 'jspdf';


const DownloadCV = () => {
  return (
    <div>
      <h1>Télécharger mon CV</h1>
      <a href="./documents/CVPoteauxSylvainDeveloppeurWeb.pdf" download="mon_cv.pdf">
        <Button className='button-app'>CV PDF</Button>
      </a>
    </div>
  );
};

export default DownloadCV;


// const PdfDownload = () => {
//   const downloadPdf = () => {
//     // Créer une nouvelle instance de jsPDF
//     const doc = new jsPDF();

//     // Ajouter du texte au PDF
//     doc.text("Bonjour, ceci est un fichier PDF généré avec jsPDF!", 10, 10);
//     doc.text("Voici une nouvelle ligne de texte.", 10, 20);

//     // Télécharger le PDF
//     doc.save("/documents.pdf");
//   };

//   return (
//     <div>
//       <h1>Télécharger un PDF</h1>
//       <Button  onClick={downloadPdf}>Télécharger le PDF</Button>
//     </div>
//   );
// };

// export default PdfDownload;