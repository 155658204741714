import React, { useEffect, useState } from 'react';
import { Navbar, Button, Collapse } from 'react-bootstrap';
import "../styles/headerStyle.css";
import "../styles/NavbarSiteStyle.css";

function NavbarSite() {
  const [activeSection, setActiveSection] = useState('');
  const [isSticky, setIsSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavbar = () => setIsOpen(!isOpen);

  useEffect(() => {
    const sections = document.querySelectorAll('section');

    const observer = new IntersectionObserver(
      (entries) => {
        // biome-ignore lint/complexity/noForEach: <explanation>
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.6 } // Active quand 60% de la section est visible
    );

    // biome-ignore lint/complexity/noForEach: <explanation>
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = document.querySelector('header').offsetHeight; // Hauteur du header
      if (window.scrollY > headerHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <Navbar id="MyNavBar" expand="lg" variant="dark" className={`navbar ${isSticky ? 'sticky' : ''}`}>
    <div id="navbar-nav" className="container-fluid">
    <button className="navbar-toggler" type="button" onClick={toggleNavbar} aria-expanded={isOpen} aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <Collapse in={isOpen}>
          <div className="navbar-collapse">
            <Button className={`button-app ${activeSection === 'skills' ? 'active' : ''}`} href="/#skills">
              Compétences
            </Button>
            <Button className={`button-app ${activeSection === 'projects' ? 'active' : ''}`} href="/#projects">
              Projets
            </Button>
            <Button className={`button-app ${activeSection === 'formation' ? 'active' : ''}`} href="./#formation">
              Formation
            </Button>
            <Button className={`button-app ${activeSection === 'portfolio' ? 'active' : ''}`} href="./portfolio">
              Portfolio
            </Button>
            <Button className={`button-app ${activeSection === 'contact' ? 'active' : ''}`} href="./#contact">
              Contact
            </Button>
          </div>
        </Collapse>
    </div>
  </Navbar>
  );
}

export default NavbarSite;




// import { Navbar, NavbarBrand, Button} from 'react-bootstrap';
// import "../styles/headerStyle.css"
// import "../styles/NavbarSiteStyle.css"

// function NavbarSite() {
//     return (
//         <Navbar id='MyNavBar' expand="lg" variant="dark" sticky="top">
//         <div id='navbar-nav' className='container-fluid '>
//           <Button className='button-app' href='/cv'>Mon cv</Button>
//           <Button className='button-app' href='/#skills'>Compétences</Button>
//           <Button className='button-app' href='/#projects'>Projets</Button>
//           <Button className='button-app' href='./#formation'>Formation</Button>
//           <Button className='button-app' href='./portfolio'>Portfolio</Button>
//           <Button className='button-app' href='./#contact'>Contact</Button>
//         </div>
//       </Navbar>
//     )
    
// }

// export default NavbarSite