import "../styles/headerStyle.css"
import NavbarSite from"../components/Navbar"
import "../styles/ProjectStyle.css"
import Footer from "./Footer"
import Header from "./Header"
//  import Snake from "../pages/SnakePage"

function Project () {
    return( 
        <>
        <div className="container-app">
        < Header />
        <NavbarSite />
            <h1 className="title-project">Retrouvez bientôt tous les exemples de vos projets ! </h1>
           {/* <Snake /> */}
        </div>
        <Footer />
        </>
    )
}

export default Project