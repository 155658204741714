import React from "react";
import PopoverComponent from "./PopoverComponent";
import  "../styles/TimeLineProjectsStyle.css"

export default function TimeLineProjects () {
    const projects = [
        {
            id: 1,
            title: "Site vitrine et CV en ligne",
            subtitle: "Votre vitrine",
            content: "site vitrine et CV",
            link:"/project",
            inverted: false
          },
          {
            id: 2,
            title: "E-commerce",
            subtitle: "Votre boutique en ligne",
            content: "boutique en ligne",
            link:"/project",
            inverted: true
          },
          {
            id: 3,
            title: "Application mobile",
            subtitle: "Votre app mobile",
            content: "application mobile",
            link:"/project",
            inverted: false
          },
          {
            id: 4,
            title: "Dashboard admin",
            subtitle: "Votre interface d'administration",
            content: "dashboard administrateur",
            link:"/project",
            inverted: true
          }
    ];

    return (
      <>
          <div className="title-project"><h1>Vos projets</h1></div>
      <div className='project'>      
        <div className='heading'>
        </div>
        {projects.map((project) => (
          <div className='badge-container' key={project.id}>
            <div className={project.inverted ? 
              'timeline-panel-container-inverted' : 
              'timeline-panel-container'}>
              <div className='timeline-heading'>
                <h3>{project.title}</h3>
                <h4>{project.subtitle}</h4>
                <PopoverComponent
                  title="réalisation"
                  content={project.content}
                  linkHref= {project.link}
                  buttonText='voir plus'
                  placement={project.inverted ? 'left-end' : 'left-end'}
                  buttonVariant='info'
                />
              </div>
            </div>
            <div className="timeline-badge">
              <span className="glyphicon glyphicon-briefcase" />
            </div>
          </div>
        ))}
      </div>
      </>
    )
}  
