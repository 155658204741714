import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import "../styles/footerStyle.css"

function Footer () {

    return (
    <footer className="text-center">
            <a href="#logo"><span class="bi-chevron-up" /></a>
            <h5>STUDIO-PURPLE</h5>
        </footer>
    )
}

export default Footer