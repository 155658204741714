
// export default App;
import React, {useEffect} from 'react';
import { Navbar, NavbarBrand, Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {Link, useLocation} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import ProgressBar from "./components/ProgressBar";
import PopoverComponent from './components/PopoverComponent';
import TimeLineProjects from './components/TimeLineProjects';
import SkillChart from './components/SkillChart';
import DonutChart from './components/SkillChart';
import Header from './components/Header';
import Footer from './components/Footer';
import ContactForm from './components/Contact';
import NavbarSite from './components/Navbar';
import CardSite from './components/Card.Site';

import './styles/headerStyle.css';
function App() {
  const location = useLocation();
  
  const cardContent = [
    {
      id:1,
      img:"../images/portfolio-2.jpg",
      title:"Site vitrine",
      text:" Un site pour présenter votre entreprise ou vos services"
    },
    {
      id:2,
      img:"../images/e-commerce.webp",
      title:"e-commerce",
      text:"Votre boutique en ligne"
    },
    {
      id:3,
      img:"../images/appli-mobile.png",
      title:"application mobile",
      text:"Votre application mobile"
    },
  ]    
  useEffect(() => {
    if(location.hash) {
      const anchorElement = document.getElementById(location.hash.substring(1));
      if(anchorElement) {
        anchorElement.scrollIntoView({behavior : "smooth"});
      }
     }
  },[location])

  return (
    <>
      <div className='container-app'>
          <Header/>
          <NavbarSite />
        <section className='container-skills' id='skills'>
          <div className='row-bar'>
            <div className='progress-container'>
              <h1 className='title-project'>Compétences</h1>   
                <ProgressBar label="HTML/CSS" percentage={80} />
                <ProgressBar label="javascript" percentage={80} />
                <ProgressBar label="Reactjs" percentage={85} />
                <ProgressBar label="nodejs" percentage={85} /> 
                <ProgressBar label="SQL" percentage={65} />
                <ProgressBar label="React Native" percentage={50} />
                <ProgressBar label="PHP" percentage={50} />              
            </div>
          </div>
          <Button className='button-app' href='/cv' >
            Voir diagramme de compétences
          </Button>
        </section>
        <section id='projects'>
          <TimeLineProjects/>
        </section>   
        <section id='portfolio'>
          <h1 className='title-project'>Porfolio exemple</h1>
          <div className="card-container-first">
                {cardContent.map((card) => (
                   <div className="card-container" key={card.id}>
                    <CardSite
                    title={card.title} // Utilisation des bons noms de props
                    img={card.img}
                    text={card.text}
                    />
                  </div>
          ))}
        </div>
        <div className='portfolio-link'>
          <Button className='button-app '  href="./portfolio">
            voir le portfolio
          </Button>

        </div>
        </section>
        <section id="contact">
          <ContactForm />
        </section >
    </div>
      < Footer />
    </>
  );
}

export default App;