import React from 'react';
import '../styles/headerStyle.css'
function ProgressBar  ({ label, percentage }) {
  const progressBarStyle = {
    width: `${percentage}%`
  };

  return (
    <div className="progress">
      {/* biome-ignore lint/a11y/useFocusableInteractive: <explanation> */}
      <div 
        className="progress-bar" 
        role="progressbar" 
        aria-label={`Example with label: ${label}`} 
        // style={progressBarStyle} 
        style={{ width: `${percentage}%` }}
        aria-valuenow={percentage} 
        aria-valuemin="0" 
        aria-valuemax="100"
      >
        <h5 style={{marginTop:"10px"}}>{label} {percentage}%</h5>
      </div>
    </div>
  );
};

export default ProgressBar;