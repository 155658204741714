import React ,{Link} from "react";
import Header from "./Header"
import NavbarSite from "./Navbar"
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button,Card } from 'react-bootstrap';
import CardSite from "./Card.Site";

import "../styles/headerStyle.css"
import "../styles/NavbarSiteStyle.css"
import Footer from "./Footer";

function Portfolio () {
  const cardContent = [
    {
      id:1,
      img:"../images/portfolio-2.jpg",
      title:"Site vitrine",
      text:" Un site pour présenter votre entreprise ou vos services"
    },
    {
      id:2,
      img:"../images/e-commerce.webp",
      title:"e-commerce",
      text:"Votre boutique en ligne"
    },
    {
      id:3,
      img:"../images/appli-mobile.png",
      title:"application mobile",
      text:"Votre application mobile"
    },
    {
      id:4,
      img:"../images/dashboard.png",
      title:"dashboard administrateur",
      text:"Gerer votre site"
    },
    {
      id:5,
      img:"../images/cv-enligne.png",
      title:"CV en ligne",
      text:"Votre CV en ligne  "
    },
  ]
    return (
      <div className="container-app">
          <Header />
          < NavbarSite />
            <h1>
                Retrouvez prochainement toutes mes créations 
            </h1>
            <div className="card-container-first">
                {cardContent.map((card) => (
                   <div className="card-container" key={card.id}>
                    <CardSite
                    title={card.title} // Utilisation des bons noms de props
                    img={card.img}
                    text={card.text}
                    />
                  </div>
          ))}
        </div>
              <Footer />
      </div>
        
    )
}
export default Portfolio