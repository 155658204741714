import React from "react";
import { Navbar, NavbarBrand, Button } from 'react-bootstrap';
import "../styles/headerStyle.css"


export default function Header () {
    return(
        <div className="App">
            <header>
              <div className='container-header'>
                <div id="logo">
                  <Button type="button" className="btn btn-info" href="/">SP</Button>
                </div>
                <div className='wrapper'>
                  <h1>Studio-purple</h1>
                  <nav>
                    <ul>
                      <li>Freelance Développeur </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </header>
        </div>
    )
}