import React from "react";
import Header from "../components/Header";
import NavbarSite from "../components/Navbar";



export default function Home () {


    return (
        <div className="container-app ">
            <Header/>
            <NavbarSite />
        </div>
    )
}